import { SVGEditorTempSvgProductsF } from '../../../../TempSvgProducts/F/Function/module/SVGEditorTempSvgProductsF.js';
import { setProductAfterVectorEditor } from './setProductAfterVectorEditor.js';
import { find, go, sel, html } from 'fxjs/es';
import { makeCanvasByUrl } from '../../../../../Canvas/S/util.js';
import { svgdataurlToCanvas } from '../../../../../NewMaker/BaseProducts/KeyRing/F/Function/private/utilRender.js';
import { trimCanvasSize } from '../../../../../Maker/F/canvas_trim.js';
import { NewMakerCvObjectUtilF } from '../../../../../NewMaker/CvObject/Util/F/Function/module/NewMakerCvObjectUtilF.js';
import { NewMakerBpOptionF } from '../../../../../NewMaker/BpOption/F/Function/module/NewMakerBpOptionF.js';
import { NewMakerCompositeF } from '../../../../../NewMaker/Composite/F/Function/module/NewMakerCompositeF.js';
import { getCvDesigns } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { BpOptionAcrylicFigureConstantS } from '../../../../../BpOption/AcrylicFigure/S/Constant/module/BpOptionAcrylicFigureConstantS.js';
import { BpOptionAcrylicFigureS } from '../../../../../BpOption/AcrylicFigure/S/Function/module/BpOptionAcrylicFigureS.js';
import { BpOptionF } from '../../../../../BpOption/F/Function/module/BpOptionF.js';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { OMPCoreUtilF } from '../../../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

export const acrylicFigureProcessByFile = async (
  { product, lockable_bp_option_group_els, bp_option_groups_el },
  {
    cutting_line_svg_file,
    makePrintablePNGFile,
    stand_leg_position_ratio,
    original_svg_file_url,
    original_svg_file_name,
  },
) => {
  const printable_png_file = await makePrintablePNGFile();
  setProductAfterVectorEditor({
    lockable_bp_option_group_els,
    bp_option_groups_el,
    product,
    original_svg_file_url,
    printable_file: printable_png_file,
    cutting_line_svg_file,
    original_svg_file_name,
  });
  await setThumbnailAndPrice({
    stand_leg_position_ratio,
    cutting_line_svg_file,
    printable_png_file,
    product,
    bp_option_groups_el,
  });
};
export const acrylicFigurePostProcess =
  ({ product, lockable_bp_option_group_els, bp_option_groups_el }) =>
  async ({ original_svg_file, cutting_line_svg_file, makePrintablePNGFile, stand_leg_position_ratio }) => {
    const formData = new FormData();
    formData.append('original_svg_file', await original_svg_file);
    const { original_svg_file_url } = await $.upload(formData, {
      url: '/@fileUpload/svg_images_images/upload_svg_product_materials',
      data: {},
    });
    await SVGEditorTempSvgProductsF.saveTempSvgProduct({
      bp_option_ids: product._.selected_option_group.bp_option_ids,
      original_svg_file_url,
      user_id: box.sel('is_user->id'),
      base_product_id: product.base_product_id,
    });
    const printable_png_file = await makePrintablePNGFile();
    setProductAfterVectorEditor({
      lockable_bp_option_group_els,
      bp_option_groups_el,
      product,
      original_svg_file_url,
      printable_file: printable_png_file,
      cutting_line_svg_file,
    });
    await setThumbnailAndPrice({
      stand_leg_position_ratio,
      cutting_line_svg_file,
      printable_png_file,
      product,
      bp_option_groups_el,
    });
  };
async function setThumbnailAndPrice({
  stand_leg_position_ratio,
  cutting_line_svg_file,
  printable_png_file,
  product,
  bp_option_groups_el,
}) {
  const cutting_line_svg_str = await new Promise((resolve, reject) => {
    const file_reader = new FileReader();
    file_reader.addEventListener('error', (event) => {
      console.error(event);
      reject(new Error('cutting_line_svg_file 을 읽을 수 없습니다.'));
    });
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.readAsText(cutting_line_svg_file);
  });

  const printable_data_url = await new Promise((resolve, reject) => {
    const file_reader = new FileReader();
    file_reader.addEventListener('error', (event) => {
      console.error(event);
      reject(new Error('printable_png_file 을 읽을 수 없습니다.'));
    });
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.readAsDataURL(printable_png_file);
  });
  const design_canvas = await makeCanvasByUrl(printable_data_url);
  const { width, height } = design_canvas;
  const outline_canvas = await svgdataurlToCanvas({ svg_data_url: cutting_line_svg_str, width, height });

  const { width: trim_width, height: trim_height } = trimCanvasSize(outline_canvas);
  const real_size = {
    width: Math.floor((trim_width / 300) * 2.54 * 10),
    height: Math.floor((trim_height / 300) * 2.54 * 10 - 3),
  };
  const acrylic_stand_canvas = await NewMakerCompositeF.acrylicEffect({
    outline_canvas,
    design_canvas,
  });
  const selected_option_group = product._.selected_option_group;
  const base_products_bp_option_groups = product._.base_product._.base_products_bp_option_groups;
  const stand_bottom = go(
    BpOptionAcrylicFigureConstantS.ALL_SIZES,
    find((bottom_option) => selected_option_group.bp_option_ids.includes(bottom_option.bp_option_id)),
    sel('stand_bottom'),
  );
  const bottom_canvas = await makeCanvasByUrl(stand_bottom.url);
  const bottom_center = {
    x: stand_bottom.x / bottom_canvas.width,
    y: stand_bottom.y / bottom_canvas.height,
  };
  const result_canvas = go(document.createElement('canvas'), (c) => {
    const margin = Math.ceil(bottom_canvas.width / 2 + 10);
    const x_location = margin + acrylic_stand_canvas.width * stand_leg_position_ratio.x;
    c.width = margin * 2 + acrylic_stand_canvas.width;
    c.height = acrylic_stand_canvas.height + bottom_canvas.height;
    const ctx = c.getContext('2d');
    ctx.drawImage(
      bottom_canvas,
      0,
      0,
      bottom_canvas.width,
      bottom_canvas.height,
      x_location - bottom_canvas.width * bottom_center.x,
      acrylic_stand_canvas.height * stand_leg_position_ratio.y - bottom_canvas.height * bottom_center.y,
      bottom_canvas.width,
      bottom_canvas.height,
    );
    ctx.drawImage(
      acrylic_stand_canvas,
      0,
      0,
      acrylic_stand_canvas.width,
      // acrylic_stand_canvas.height * stand_leg_position_ratio.y + (0.3 / 2.54) * 300,
      acrylic_stand_canvas.height * stand_leg_position_ratio.y,
      margin,
      0,
      acrylic_stand_canvas.width,
      // acrylic_stand_canvas.height * stand_leg_position_ratio.y + (0.3 / 2.54) * 300,
      acrylic_stand_canvas.height * stand_leg_position_ratio.y,
    );
    const { top, left, width, height } = trimCanvasSize(c, 0);
    const image_data = ctx.getImageData(left, top, width, height);
    const left_of_center = x_location - left;
    const right_of_center = width - left_of_center;
    const result_canvas = document.createElement('canvas');
    const result_ctx = result_canvas.getContext('2d');
    const plus_margin = Math.abs(right_of_center - left_of_center);
    result_canvas.width = plus_margin + width;
    result_canvas.height = height;
    if (right_of_center > left_of_center) {
      result_ctx.putImageData(image_data, plus_margin, 0);
    } else {
      result_ctx.putImageData(image_data, 0, 0);
    }
    return result_canvas;
  });
  const fcanvas = G.mp.maker.editing_canvas();
  await go(
    NewMakerCvObjectUtilF.makeImageObjForCvObj(result_canvas.toDataURL()),
    (image) =>
      NewMakerBpOptionF.makeCenterImageByPxPer1Cm({
        image,
        fcanvas,
      }),
    (cv_image) => {
      go(
        getCvDesigns(fcanvas._objects),
        find((o) => o.cid === 'c2323'),
        (o) => o && o.remove(),
      );
      cv_image._data.fitted_size = real_size;
      cv_image.cid = 'c2323';
      cv_image._data.stand_leg_position_ratio = stand_leg_position_ratio;
      fcanvas.add(cv_image);
      return cv_image;
    },
  );
  BpOptionAcrylicFigureS.setBpOptionByFittedSize({
    fitted_size: real_size,
    base_products_bp_option_groups,
    selected_option_group,
    product,
  });

  await BpOptionF.makeSelectedOptionPriceC2323({
    base_products_bp_option_groups: product._.base_product._.base_products_bp_option_groups,
    bp_option_groups_el,
    selected_option_group,
  });
  const price = product['price' + _en];
  if (G.collabo_type !== 'creator') priceAlertBySize({ price, fitted_size: real_size });
}
function priceAlertBySize({ fitted_size, price }) {
  const bottom_stand_size = 3;
  // const color = G.collabo_type === '' ? '#FF6B00' : '#0157ff';
  if (G.collabo_type === '') {
    return OMPCoreUtilF.titleAlert({
      title: T('maker_error_alert::크기에 맞춰 가격이 책정되었어요.'),
      content: `${T('maker_basic_word::사이즈')}(${T('maker_basic_word::스탠드 포함')}): ${
        fitted_size.width
      } x
        ${fitted_size.height + bottom_stand_size} mm<br />${T('maker_basic_word::가격')}:
        ${UtilS.makeCurrencyPrice(PriceS.pricify(price), T.lang)}`,
      style: { content_color: 'OG' },
    });
  }
  const color = '#0157ff';
  return $.alert(html`
    <div>${T('maker_error_alert::크기에 맞춰 가격이 책정되었어요.')}</div>
    <div style="color:${color};">
      ${T('maker_basic_word::사이즈')}(${T('maker_basic_word::스탠드 포함')}): ${fitted_size.width} x
      ${fitted_size.height + bottom_stand_size} mm<br />${T('maker_basic_word::가격')}:
      <span>${UtilS.makeCurrencyPrice(PriceS.pricify(price), T.lang)}</span>
    </div>
  `);
}
