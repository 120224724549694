import { html, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

export const reviewHeaderTmpl = ({ is_mobile, cate_lists, query, is_best, review_total }) => {
  const photo_review_label = TT('review::review_02');

  const current_filter = cate_lists.find(
    (category) => category.id.toString() === (query.cate_list_id || 'all'),
  );

  return html`
    <div class="omp-review__header">
      ${reviewHeaderTitleTmpl({ is_best, review_total })}
      <span class="omp-review__header-option">
        ${is_mobile
          ? html`
              <div class="omp-review__header-option__photo-review">
                <span class="omp-review__header-option__photo-review-wrapper">
                  ${OMPCoreAtomTmplS.toggle({
                    id: 'photo-review',
                    active: !query.is_all || query.is_all === 'false',
                  })}
                </span>
                <span class="omp-review__header-option__photo-review-label"> ${photo_review_label} </span>
              </div>
              ${cate_lists.length
                ? html` <label class="omp-review__header-option__product-filter">
                    <div class="omp-review__header-option__product-filter-label">
                      <span class="omp-review__header-option__product-filter-title">
                        ${current_filter?.name}
                      </span>
                      <span class="omp-review__header-option__product-filter-icon">
                        ${OMPCoreAtomTmplS.arrowIcon({ direction: 'down', color: 'GY' })}
                      </span>
                    </div>
                    <select class="omp-review__header-option__product-filter-list">
                      ${strMap((category) => {
                        return html`
                          <option
                            data-active="${category.id === current_filter.id}"
                            data-cate_list_id="${category.id}"
                            class="omp-review__header-option__product-filter-item"
                          >
                            ${category.name}
                          </option>
                        `;
                      }, cate_lists)}
                    </select>
                  </label>`
                : ''}
            `
          : html`
              ${cate_lists.length
                ? html`<div class="omp-review__header-option__product-filter">
                    <div
                      data-current_value="${parseInt(query.cate_list_id) || 'all'}"
                      class="omp-review__header-option__product-filter-label"
                    >
                      <span class="omp-review__header-option__product-filter-title">
                        ${current_filter?.name}
                      </span>
                      <span class="omp-review__header-option__product-filter-icon">
                        ${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}
                      </span>
                    </div>
                    <div class="omp-review__header-option__product-filter-list">
                      ${strMap((category) => {
                        return html`
                          <div
                            data-active="${category.id === current_filter.id}"
                            data-cate_list_id="${category.id}"
                            class="omp-review__header-option__product-filter-item"
                          >
                            ${category.name}
                          </div>
                        `;
                      }, cate_lists)}
                    </div>
                  </div>`
                : ''}
              <div class="omp-review__header-option__photo-review">
                <span class="omp-review__header-option__photo-review-wrapper">
                  ${OMPCoreAtomTmplS.toggle({
                    id: 'photo-review',
                    active: !query.is_all || query.is_all === 'false',
                  })}
                </span>
                <span class="omp-review__header-option__photo-review-label"> ${photo_review_label} </span>
              </div>
            `}
      </span>
    </div>
  `;
};

const reviewHeaderTitleTmpl = ({ is_best, review_total }) => {
  const best_review_title = TT('review::review_08');
  const review_title = TT('review::review_01');

  if (TT.lang === 'kr')
    return html`<a class="omp-review__header-title" href="/event/detail/665">
      <span>
        ${is_best
          ? best_review_title
          : `${review_title} (${UtilS.commify(review_total?.count || review_total)})`}
      </span>
      <div class="omp-review__event-link" href="/event/detail/665">
        <span class="omp-review__event-link-text">이벤트 안내</span>
        <svg
          class="omp-review__event-link-icon"
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 10.5L6 6L1.5 1.5"
            stroke="currentColor"
            stroke-width="1.125"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </a>`;

  return html` <span class="omp-review__header-title">
    ${is_best ? best_review_title : `${review_title} (${UtilS.commify(review_total?.count || review_total)})`}
  </span>`;
};
