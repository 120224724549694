import axios from 'axios';

/**
 * API 호출을 추상화한 함수
 * @param {Object} config - axios 설정 객체
 * @param {number} [retryCount=0] - 재시도 횟수
 * @param {number} [timeout=30000] - 타임아웃 (밀리초)
 * @returns {Promise & { abort: Function }} Promise 객체와 abort 함수
 */
export const apiCall = (config, retryCount = 0, timeout = 30000, retryInterval = 1000) => {
  const controller = new AbortController();
  let currentRetry = 0;

  const executeCall = async () => {
    try {
      const response = await axios({
        ...config,
        signal: controller.signal,
        timeout,
      });
      return response;
    } catch (error) {
      if (error.name === 'AbortError' || error.name === 'CanceledError') {
        throw error;
      }

      if (currentRetry < retryCount) {
        currentRetry++;
        await new Promise((resolve) => setTimeout(resolve, retryInterval));
        return executeCall();
      }

      throw error;
    }
  };

  const promise = executeCall();

  // abort 함수를 추가한 Promise 반환
  return Object.assign(promise, {
    abort: () => controller.abort(),
  });
};
