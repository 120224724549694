import { go } from 'fxjs/es';
import {
  createCanvasElement,
  makeCanvasByUrl,
  makeCanvasCutByLocation,
} from '../../../../../Canvas/S/util.js';
import {
  func2,
  func4,
  getTrimmedCanvas,
  keyringEffect,
  svgdataurlToCanvas,
  svgDataUrlToWhiteCanvasUrl,
} from './private/utilRender.js';
import { trimCanvasSize } from '../../../../../Maker/F/canvas_trim.js';
import { BpOptionKeyRingConstantS } from '../../../../../BpOption/KeyRing/S/Constant/module/BpOptionKeyRingConstantS.js';

export async function svgAndPngToCvImages({
  svg_data_url,
  png_data_url,
  hook_position_ratio,
  material,
  ratio,
}) {
  let png_canvas = await makeCanvasByUrl(png_data_url);
  const { width, height } = png_canvas;
  const svg_canvas = await svgdataurlToCanvas({ svg_data_url, width, height });
  const {
    top: trim_top,
    left: trim_left,
    width: trim_width,
    height: trim_height,
  } = trimCanvasSize(svg_canvas);
  const real_size = {
    width: Math.round((trim_width / ratio / 300) * 2.54 * 10),
    height: Math.round((trim_height / ratio / 300) * 2.54 * 10),
  };
  hook_position_ratio.x = (hook_position_ratio.x * width - trim_left) / trim_width;
  hook_position_ratio.y = (hook_position_ratio.y * height - trim_top) / trim_height;
  const svg_canvas_data_url = await go(
    svg_canvas,
    (canvas) =>
      getTrimmedCanvas({
        canvas,
        top: trim_top,
        left: trim_left,
        width: trim_width,
        height: trim_height,
      }),
    (c) => c.toDataURL(),
  );
  png_canvas = getTrimmedCanvas({
    canvas: png_canvas,
    top: trim_top,
    left: trim_left,
    width: trim_width,
    height: trim_height,
  });
  const svg_white_canvas_data_url = await go(
    svgDataUrlToWhiteCanvasUrl({ svg_data_url, width, height }),
    makeCanvasByUrl,
    (canvas) =>
      getTrimmedCanvas({
        canvas,
        top: trim_top,
        left: trim_left,
        width: trim_width,
        height: trim_height,
      }),
    (c) => c.toDataURL(),
  );
  const { data_url: acril_data_url, coord: acril_coord } = await go(
    svg_canvas_data_url,
    async (data_url) => func2(await makeCanvasByUrl(data_url), hook_position_ratio, ratio),
    async (data_url) => func4(await makeCanvasByUrl(data_url), hook_position_ratio),
  );
  const { data_url: white_data_url } = await go(
    svg_white_canvas_data_url,
    async (data_url) => func2(await makeCanvasByUrl(data_url), hook_position_ratio, ratio),
    async (data_url) => func4(await makeCanvasByUrl(data_url), hook_position_ratio),
  );
  const design_data_url = await go(func4(png_canvas, hook_position_ratio), async ({ data_url }) => {
    const c = await makeCanvasByUrl(data_url);
    const ctx = c.getContext('2d');
    ctx.globalCompositeOperation = 'destination-in';
    ctx.drawImage(await makeCanvasByUrl(acril_data_url), 0, 0);
    return c.toDataURL();
  });

  const material_data_url =
    material === 'gl' &&
    (await go(
      makeCanvasByUrl(BpOptionKeyRingConstantS.KEYRING_MATERIAL_GL_URL),
      (c) => {
        const cc = createCanvasElement({
          width: (11 / 2.54) * 300 * ratio,
          height: (11 / 2.54) * 300 * ratio,
        });
        const ctx = cc.getContext('2d');
        ctx.drawImage(c, 0, 0, c.width, c.height, 0, 0, cc.width, cc.height);
        return cc;
      },
      (c) => {
        return makeCanvasCutByLocation(c, {
          width,
          height,
          left: 0,
          top: 0,
        });
      },
      async (c) => func4(c, hook_position_ratio),
      async ({ data_url }) => {
        const c = await makeCanvasByUrl(data_url);
        const ctx = c.getContext('2d');
        ctx.globalCompositeOperation = 'destination-in';
        ctx.drawImage(await makeCanvasByUrl(acril_data_url), 0, 0);
        return c.toDataURL();
      },
    ));
  const result = await keyringEffect({
    white_data_url,
    acril_data_url,
    design_data_url,
    material_data_url,
    material,
    acril_coord,
    ratio,
  });
  return {
    ...result,
    real_size,
  };
}
