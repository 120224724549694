import { $appendTo, $attr, $closest, $delegate, $el, $findAll, $qsa, $remove, $setCss } from 'fxdom/es';
import { compact, delay, each, flatMap, go, html, map, reject, sel, some } from 'fxjs/es';
import { DfOutsourcingCompanyDownloadF } from '../../../../Outsourcing/Company/Download/F/Function/module/DfOutsourcingCompanyDownloadF.js';

export function makeOnlyMyUps() {
  go(
    $qsa('.task_item .up_item'),
    reject((up_item_el) => {
      const { parent_id: task_parent_id } = go(up_item_el, $closest('.task_item'), box.sel);
      return go(box.sel(up_item_el), (up) => {
        const task_set = up._.base_product._.task_set;
        return go(
          task_set?._?.tasks,
          some((task) => {
            return task.parent_id === task_parent_id;
          }),
        );
      });
    }),
    each($setCss({ display: 'none' })),
  );
}
export function makeOnlyMyTasks(task_el) {
  const t_id = go(task_el, $attr('task_id'));
  go(
    task_el,
    $findAll('.task_items [type="printing"] .task'),
    reject((task_el) => {
      const task = go(task_el, box.sel);
      return task.id == t_id;
    }),
    each($setCss({ display: 'none' })),
  );
  go(
    task_el,
    $findAll('.task_items [type="before_deliver"] .task'),
    reject((task_el) => {
      const task = go(task_el, box.sel);
      return task.id == t_id;
    }),
    each($setCss({ display: 'none' })),
  );
}
export const init = (image_work_wrapper_el) => {
  if (!box()?.is_user?._?.policies?.outsourcing_company_zip_download_policy) return;
  go(image_work_wrapper_el, $findAll('.image_work'), each($remove));
  go(
    html`
      <div style="margin-bottom:16px;">
        <button type="button" class="download_service">
          약 30개의 상품 인쇄용 파일, 정보 다운로드 및 태스크 진행중으로 변경
        </button>
      </div>
    `,
    $el,
    $appendTo(image_work_wrapper_el),
    $delegate('click', '.download_service', async (e) => {
      $.don_loader_start();
      const error_projection_ids = [];
      const up_c_s_projections = await go(
        $.get('/@api/df/outsourcing_company/download/task_projections'),
        sel('up_c_s_projections'),
        map((up_c_s_projection) => {
          const is_not_image_task = up_c_s_projection._.base_product.is_not_image_task;
          const _product_faces2 =
            up_c_s_projection._.printable_product?.product_faces2 ||
            up_c_s_projection._.product_color?.product_faces2;
          const product_faces2 = _product_faces2.value;
          const all_faces_single_color = _product_faces2.all_faces_single_color?.id
            ? _product_faces2.all_faces_single_color
            : null;
          const GAON_PAD_BASE_PRODUCT_IDS = [4404, 4405, 4906];
          const product_faces2_printable_files =
            !GAON_PAD_BASE_PRODUCT_IDS.includes(up_c_s_projection._.base_product.id) &&
            !is_not_image_task &&
            go(
              up_c_s_projection._.printable_product?.product_faces2?.value,
              flatMap((pf) =>
                go(
                  pf.printable_files,
                  map(
                    (printable_file) =>
                      printable_file?.url && {
                        ...printable_file,
                        bpf_id: pf.bpf_id,
                        face_name: pf.face_name,
                      },
                  ),
                ),
              ),
              compact,
            );
          if (
            !product_faces2 ||
            (!is_not_image_task &&
              !up_c_s_projection._.base_product.has_template &&
              !product_faces2_printable_files?.length)
          ) {
            error_projection_ids.push(up_c_s_projection.projection_id);
            return {
              value: up_c_s_projection,
              product_faces2,
              product_faces2_printable_files,
              all_faces_single_color,
            };
          }

          return {
            value: up_c_s_projection,
            product_faces2,
            product_faces2_printable_files,
            all_faces_single_color,
          };
        }),
        reject((up_c_ss_projection) => error_projection_ids.includes(up_c_ss_projection.value.projection_id)),
      );
      $.don_loader_end();
      if (error_projection_ids?.length)
        await $.alert(`(${error_projection_ids.join(', ')}) 주문서들에 이미지 작업이 안된 상품이 있습니다.`);
      if (!up_c_s_projections?.length) {
        await $.alert(`다운로드할 파일이 없습니다. 아래에 이슈를 읽지 않았다면 이슈를 모두 읽어주세요.`);
        return;
      }

      await delay(100, () => {});
      const is_projection_id_folder = box().is_user.id === 2050544;
      await DfOutsourcingCompanyDownloadF.makeZipFile(up_c_s_projections, is_projection_id_folder);
    }),
    $delegate('click', '.download_all_image_work', async (e) => {
      return $.alert('더 이상 사용할수 없습니다. 오른쪽 버튼을 이용해주세요.');
      // await G.df.task.list_update();
      // const user_id = box().is_user.id;
      // let done_percent_loader;
      // try {
      //   const zip = new JSZip();
      //   const all_task_items = $qsa('.task_item');
      //   await go(
      //     all_task_items,
      //     filter((task_item_el) => box.sel(task_item_el).status === 'ready'),
      //     reject((task_item_el) =>
      //       some((issue) => $.get('/@api/issue/check_false_read', { issue_id: issue.id, user_id }))(
      //         box.sel(task_item_el)._.issues,
      //       ),
      //     ),
      //     filter((task_item_el) => {
      //       return go(
      //         task_item_el,
      //         $findAll('.up_item'),
      //         filter((up_item_el) => {
      //           const up = box.sel(up_item_el);
      //           const task_set = up._.base_product._.task_set;
      //           const user_id = box.sel('is_user').id;
      //           if (!(up._.base_product.has_template || up._.base_product.is_not_image_task)) return;
      //           return go(
      //             task_set?._?.tasks,
      //             some((task) => {
      //               return task.worker_id === user_id;
      //             }),
      //           );
      //         }),
      //         flatMap((up_item_el) => box.sel(up_item_el)._.up_cs),
      //         every(
      //           (up_c) =>
      //             (up_c._.base_product.is_not_image_task || up_c._.printable_product?.id) &&
      //             some((up_c_s) => up_c_s.quantity > 0)(up_c._.up_c_ss),
      //         ),
      //       );
      //     }),
      //     flatMap((task_item_el) => {
      //       const { parent_id: task_parent_id } = box.sel(task_item_el);
      //       return go(
      //         task_item_el,
      //         $findAll('.up_item'),
      //         filter((up_item_el) => {
      //           const up = box.sel(up_item_el);
      //           const task_set = up._.base_product._.task_set;
      //           return go(
      //             task_set?._?.tasks,
      //             find((task) => task.parent_id === task_parent_id),
      //           );
      //         }),
      //         map((up_item_el) => {
      //           const is_not_image_task = box.sel(up_item_el)._.base_product.is_not_image_task;
      //           if (is_not_image_task) {
      //             return (
      //               $find('.auto_print_img:not(.by_customer)')(up_item_el) ||
      //               $find('.auto_print_img.by_customer')(up_item_el)
      //             );
      //           }
      //           return $find('.auto_print_img:not(.by_customer)')(up_item_el);
      //         }),
      //       );
      //     }),
      //     tap(async (auto_print_img_els) => {
      //       const auto_print_img_el = auto_print_img_els[0];
      //       if (auto_print_img_el) {
      //         const start = new Date();
      //         const one_work = await (async () => {
      //           const up_c = _p.go(auto_print_img_el, $.closest('.up_c_item'), box.sel);
      //           const task = go(auto_print_img_el, $closest('.task_item'), box.sel);
      //           const task_id = go(auto_print_img_el, $closest('.task_item'), $attr('task_id'));
      //
      //           const base_product = up_c._.base_product;
      //           const base_product_color = up_c._.base_product_color;
      //           const up_c_ss = go(
      //             up_c._.up_c_ss,
      //             filter((up_c_s) => up_c_s.quantity && up_c_s.base_product_size_id),
      //             take(1),
      //           );
      //           const { merged_type, important } = task._.projection.merged_type;
      //           if (!up_c_ss.length) return;
      //           if (
      //             VectorEditorConstantS.KEYRING_EDITOR == base_product.maker_type ||
      //             VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR == base_product.maker_type
      //           )
      //             return SVGEditorUtilF.vectorDownload({
      //               product_id: up_c.product_color_id,
      //               task_id,
      //               up_c_s_id: up_c_ss[0].id,
      //             });
      //           const { product_faces2 } = _p.go(auto_print_img_el, $.closest('.product_color'), box.sel);
      //           return NewMakerPrintResultF.makeUpcsCanvasBlob({
      //             important,
      //             up_c,
      //             up_c_ss,
      //             base_product,
      //             base_product_color,
      //             product_faces2: product_faces2.value,
      //             task_id,
      //             merged_type,
      //           });
      //         })();
      //         const end = new Date();
      //         if (one_work) {
      //           const second_per_one_work = end - start + 1000;
      //           const expected_file_length = go(
      //             auto_print_img_els,
      //             map((auto_print_img_el) => {
      //               const up_c = _p.go(auto_print_img_el, $.closest('.up_c_item'), box.sel);
      //               const up_c_ss = go(
      //                 up_c._.up_c_ss,
      //                 filter((up_c_s) => up_c_s.quantity && up_c_s.base_product_size_id),
      //               );
      //               return up_c_ss.length;
      //             }),
      //             sum,
      //           );
      //           $.don_loader_end();
      //           await delay(500, () => {});
      //           done_percent_loader = imageWorkPercentLoader({
      //             message: '예상 진행 상황입니다.',
      //             time: expected_file_length * second_per_one_work,
      //             clazz: 'pc',
      //           });
      //         }
      //       }
      //     }),
      //     flatMap((auto_print_img_el) => {
      //       const up_c = _p.go(auto_print_img_el, $.closest('.up_c_item'), box.sel);
      //       const task = go(auto_print_img_el, $closest('.task_item'), box.sel);
      //       const { merged_type, important } = task._.projection.merged_type;
      //       const task_id = go(auto_print_img_el, $closest('.task_item'), $attr('task_id'));
      //       const base_product = up_c._.base_product;
      //       const base_product_color = up_c._.base_product_color;
      //       const up_c_ss = go(
      //         up_c._.up_c_ss,
      //         filter((up_c_s) => up_c_s.quantity && up_c_s.base_product_size_id),
      //       );
      //       if (
      //         VectorEditorConstantS.KEYRING_EDITOR == base_product.maker_type ||
      //         VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR == base_product.maker_type
      //       )
      //         return SVGEditorUtilF.vectorDownload({
      //           product_id: up_c.product_color_id,
      //           task_id,
      //           up_c_s_id: up_c_ss[0].id,
      //           up_id: up_c.up_id,
      //           quantity: up_c_ss[0].quantity,
      //           projection_id: up_c.projection_id,
      //         });
      //
      //       const { product_faces2 } = _p.go(auto_print_img_el, $.closest('.product_color'), box.sel);
      //       return NewMakerPrintResultF.makeUpcsCanvasBlob({
      //         important,
      //         up_c,
      //         up_c_ss,
      //         base_product,
      //         base_product_color,
      //         product_faces2: product_faces2.value,
      //         task_id,
      //         merged_type,
      //       });
      //     }),
      //     compact,
      //     groupBy(({ file_name }) => file_name),
      //     entries,
      //     flatMap(([k, arr]) => {
      //       return go(
      //         arr,
      //         ippL,
      //         map(([i, obj]) => {
      //           if (i === 0) return obj;
      //           const mime = last(obj.file_name.split('.'));
      //           const name = obj.file_name.split(mime)[0];
      //           obj.file_name = `${name}(${i}).${mime}`;
      //           return obj;
      //         }),
      //       );
      //     }),
      //     each(({ canvas_blob, file_name }) => {
      //       zip.file(file_name.replace(/\//g, '_'), canvas_blob);
      //     }),
      //     async (arr) => {
      //       if (arr.length) {
      //         const blob = await zip.generateAsync({ type: 'blob' });
      //         const task_ids = go(arr, pluck('task_id'), uniq);
      //         const up_c_s_ids = go(arr, pluck('up_c_s_id'));
      //         await axios.post('/@api/tasks/update_all_status_on', { task_ids });
      //         saveAs(blob, `인쇄용 파일들(${format(new Date(), 'MMddHHmmss')})`);
      //         const a = document.createElement('a');
      //         a.href = `/@api/tasks/outsourcing_up_cs_info?up_c_s_ids=${up_c_s_ids.join(',')}&image_length=${
      //           arr.length
      //         }`;
      //         a.click();
      //         await G.df.task.list_update();
      //         await $.alert(
      //           `파일 ${arr.length}개 다운로드, 태스크 ${task_ids.length}/${all_task_items.length}개 진행중으로 변경 됐습니다.`,
      //         );
      //         window.location.reload();
      //       } else {
      //         $.don_loader_end();
      //         $.alert('진행할 태스크가 없습니다.');
      //       }
      //     },
      //   );
      // } catch (e) {
      //   if (e.response?.data) {
      //     $.alert(e.response?.data);
      //   } else {
      //     $.alert(JSON.stringify(e.message));
      //   }
      // } finally {
      //   $.don_loader_end();
      //   if (done_percent_loader) done_percent_loader();
      // }
    }),
  );
};
