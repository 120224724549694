import { find, go, html, reject, sel, strMap } from 'fxjs/es';
import { UtilObjS } from '../../../../../Util/Object/S/Function/module/UtilObjS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreCellTmplS } from '../../../Cell/S/Tmpl/module/OMPCoreCellTmplS.js';
import { OMPCoreLayoutNavigationTmplS } from '../../Navigation/S/Tmpl/module/OMPCoreLayoutNavigationTmplS.js';
import { OMPCoreLayoutConstantS } from '../Constant/module/OMPCoreLayoutConstantS.js';

const defaultHeaderMenu = ({ sub_menu_all, sub_menus }) => {
  return html`
    ${OMPCoreAtomTmplS.headerMenuArrow(sub_menu_all)}
    ${OMPCoreCellTmplS.headerMenus({
      buttons: sub_menus,
    })}
  `;
};

export const hamMenuLoginHTML = (login_user) => {
  const tt_logout = TT('omp_layout::left::sub_07');
  const tt_my_page = TT('omp_layout::left::sub_06');

  if (login_user) {
    return html` <div class="mp-header-ham__item">
        <a href="/${TT.lang}/logout" class="mp-header-mo__item mp-header-mo__logout">${tt_logout}</a>
      </div>
      <div class="mp-header-ham__item login_view">
        <a href="/${TT.lang}/my-page">${tt_my_page}</a>
      </div>`;
  } else {
    return html`
      <div class="mp-header-ham__item">
        <a href="/${TT.lang}/signup" class="mp-header-mo__item mp-header-mo__sign-up ham_btn_signup"
          >${T('Sign up')}</a
        >
      </div>
      <div class="mp-header-ham__item">
        <a href="/${TT.lang}/login" class="mp-header-mo__item mp-header-mo__sign-in ham_btn_login"
          >${T('Sign in')}</a
        >
      </div>
    `;
  }
};

const langs = ['kr', 'en', 'jp'];
const hamMenusHTML = ({ login_user, ham_menus, wish_count, cart_count, mp_navigations }) => {
  const ham_menu = OMPCoreLayoutConstantS.HAM_MENU();
  const active_cate_list = find((cate_list) => cate_list.active, ham_menus) || ham_menus[0];
  if (active_cate_list) {
    active_cate_list.active = true;
  }

  const tt_live_chat = TT('omp_layout::left::sub_03');
  const tt_customer_center = TT('omp_layout::left::sub_04');
  const tt_about = TT('omp_layout::left::sub_05');

  return html`
    <div class="mp-header-ham" id="ham">
      <div class="mp-header-ham__top">
        <div class="mp-header-ham__product-list-title">
          <h2 class="mp-header-ham__product-list-title-h2">
            <a href="/${TT.lang}"
              ><img
                src="//s3.marpple.co/files/u_218933/2023/7/original/9c422462100a76c3b568e50a1faade32c9bf1c661.svg"
                alt="MARPPLE"
            /></a>
          </h2>
        </div>
        <div class="mp-header-ham__top-right">
          <div class="mp-header-mo__wish wish not_sell_family" data-count="${wish_count}">
            ${OMPCoreAtomTmplS.heartButtonLink({
              badge: wish_count,
              link: `/${TT.lang}/wish/list`,
              active: false,
            })}
          </div>
          <div class="mp-header-mo__cart cart not_sell_family" data-count="${cart_count}">
            ${OMPCoreAtomTmplS.cartButtonLink({ badge: cart_count, link: `/${TT.lang}/cart` })}
          </div>
        </div>
        <div class="mp-header-ham__menus">
          <ul
            class="mp-header-ham__menus-ul${!ham_menu.know_how
              ? ' mp-header-ham__menus-ul--not-know-how'
              : ''}"
          >
            <li class="mp-header-ham__menus-li">
              <a href="/${TT.lang}/">
                <span> ${OMPCoreAtomTmplS.customIcon()} </span>
                <span class="mp-header-ham__menus-txt">${ham_menu.make_product.title}</span>
              </a>
            </li>
            <li class="mp-header-ham__menus-li">
              <a href="/${TT.lang}/${ham_menu.group_biz_products.url}">
                <span>${OMPCoreAtomTmplS.bizGroupIcon()}</span>
                <span class="mp-header-ham__menus-txt">${ham_menu.group_biz_products.title}</span>
              </a>
            </li>
            <li class="mp-header-ham__menus-li">
              <a href="/${TT.lang}/${ham_menu.corp_goods.url}">
                <span>${OMPCoreAtomTmplS.kitIcon()}</span>
                <span class="mp-header-ham__menus-txt">${ham_menu.corp_goods.title}</span>
              </a>
            </li>
            <li class="mp-header-ham__menus-li">
              <a href="/${TT.lang}/${ham_menu.portfolio.url}">
                <span>${OMPCoreAtomTmplS.portfolioIcon()}</span>
                <span class="mp-header-ham__menus-txt">${ham_menu.portfolio.title}</span>
              </a>
            </li>
            ${ham_menu.know_how
              ? html`<li class="mp-header-ham__menus-li">
                  <a href="/${TT.lang}/${ham_menu.know_how.url}">
                    <span>${OMPCoreAtomTmplS.knowHowIcon()}</span>
                    <span class="mp-header-ham__menus-txt">${ham_menu.know_how.title}</span>
                  </a>
                </li>`
              : ''}
          </ul>
        </div>
        <div id="ham_close">
          <button type="button"></button>
        </div>
      </div>
      <div class="mp-header-ham__bottom">
        <div class="mp-header-ham__bottom-left">
          <div class="mp-header-ham__section mp-header-ham__section--cate-list-area">
            ${strMap(({ id, new_tag = false, active = false, text } = {}) => {
              return html`
                <div class="mp-header-ham__item" data-id="${id}" data-active="${active}">
                  <span class="omp-atom__category-button-text">${text}</span>
                  ${new_tag ? html`<span class="omp-atom__category-button-new-tag"></span>` : ''}
                </div>
              `;
            }, ham_menus)}
          </div>
          <div class="mp-header-ham__section">
            <div class="mp-header-ham__section-login">${hamMenuLoginHTML(login_user)}</div>

            <div class="mp-header-ham__item live_chat">${tt_live_chat}</div>
            <div class="mp-header-ham__item">
              <a
                href="/${TT.lang}/help_center?type=${TT.lang === 'kr'
                  ? 'order_shipping_kr'
                  : 'buying_shipping'}"
              >
                ${tt_customer_center}
              </a>
            </div>

            <div class="mp-header-ham__item">
              <a href="/${TT.lang}/about"> ${tt_about} </a>
            </div>

            <div class="mp-header-ham__item mp-header-ham__lang" data-is_show="false">
              <div class="mp-header-ham__lang-selected">
                <span>${TT.lang.toUpperCase()}</span>
              </div>

              <ul class="mp-header-ham__lang-ul">
                ${go(
                  langs,
                  reject((lang) => lang === TT.lang),
                  strMap((lang) => {
                    return html` <li class="mp-header-ham__lang-li">
                      <a href="/${lang}" class="omp-change-lang" data-lang="${lang}">${lang.toUpperCase()}</a>
                    </li>`;
                  }),
                )}
              </ul>
            </div>
          </div>
        </div>
        <div class="mp-header-ham__bottom-right" data-show_cate_list_id="${active_cate_list?.id}">
          ${OMPCoreLayoutNavigationTmplS.makeNavi({
            cate_list_id: active_cate_list?.id,
            is_mobile: true,
            cate_lists: ham_menus,
            mp_navigations,
            login_user,
          })}
        </div>
      </div>
    </div>
  `;
};

const mobileDefaultHeader = ({ wish_count, cart_count, title, menu_title, is_back }) => {
  const titleHTML = () => {
    if (menu_title) {
      return html`
        <h1 class="mp-header-mo__h1-menu-title" data-show="false">
          <span class="mp-header-mo__h1-menu-title-txt">${menu_title}</span>
          <span class="mp-header-mo__h1-menu-title-arrow"> ${OMPCoreAtomTmplS.triangleIcon2()} </span>
        </h1>
      `;
    } else if (title) {
      return html`<h1 class="mp-header-mo__h1-txt">${title}</h1>`;
    } else {
      return html`
        <h1 class="mp-header-mo__h1">
          <a href="/${TT.lang}/"
            ><img
              src="//s3.marpple.co/files/u_218933/2023/7/original/9c422462100a76c3b568e50a1faade32c9bf1c661.svg"
              alt="MARPPLE"
          /></a>
        </h1>
      `;
    }
  };

  return html`
    <div class="mp-header-mo__top">
      <div class="mp-header-mo__left">
        ${is_back ? html`<div class="mp-header-mo__btn-prev">${OMPCoreAtomTmplS.arrowButton()}</div>` : ''}
        <div class="mp-header-mo__ham-btn" id="ham_button">${OMPCoreAtomTmplS.hamButton()}</div>
      </div>
      <div class="mp-header-mo__center">${titleHTML()}</div>
      <div class="mp-header-mo__right">
        <div class="mp-header-mo__wish wish not_sell_family" data-count="${wish_count}">
          ${OMPCoreAtomTmplS.heartButtonLink({
            badge: wish_count,
            link: `/${TT.lang}/wish/list`,
            active: false,
          })}
        </div>
        <div class="mp-header-mo__cart cart not_sell_family" data-count="${cart_count}">
          ${OMPCoreAtomTmplS.cartButtonLink({ badge: cart_count, link: `/${TT.lang}/cart` })}
        </div>
      </div>
    </div>
  `;
};

/**
 * @param {object} param
 * @param {string?} param.search_value 검색 페이지 검색창 초기값
 */
const mobileSearchHeader = ({ search_value, is_back }) => {
  return html`
    <div class="mp-header-mo__top">
      <div class="mp-header-mo__search-area ${is_back ? 'mp-header-mo__search-area__is-back' : ''}">
        ${is_back ? html`<div class="mp-header-mo__btn-prev">${OMPCoreAtomTmplS.arrowButton()}</div>` : ''}
        <div class="mp-header-mo__search">${headerSearchFormHTML(search_value)}</div>
      </div>
    </div>
  `;
};

/**
 * @param {object} param
 * @param {string?} param.search_value 검색 페이지 검색창 초기값
 */
const mobileHTML = ({
  wish_count,
  cart_count,
  search_value,
  title,
  menu_title,
  sub_menu_all,
  sub_menus,
  header_banner,
  policies,
  login_user,
  type,
  is_back,
  ham_menus,
  mp_navigations,
  header_banner_type,
  sub_menu_items,
}) => {
  const headerBannerHTML = () => {
    return '';
    // return header_banner ? html` <div id="top-banner">top banner</div> ` : '';
  };

  const typeHeaderHtml = () => {
    if (type === OMPCoreLayoutConstantS.HEADER_TYPE.search) {
      return mobileSearchHeader({ search_value, is_back });
    } else {
      return mobileDefaultHeader({ wish_count, cart_count, title, menu_title, is_back });
    }
  };

  const subMenusHTML = () => {
    if (!sub_menus?.length) return '';
    return html`
      <div class="mp-sub-menus">
        <ul class="mp-sub-menus__ul">
          ${sub_menu_all
            ? html`<li class="mp-sub-menus__li">${OMPCoreAtomTmplS.headerMenuArrow(sub_menu_all)}</li>`
            : ''}
          ${strMap((menu) => {
            return html` <li class="mp-sub-menus__li">${OMPCoreAtomTmplS.categoryLinkSmall(menu)}</li> `;
          }, sub_menus)}
        </ul>
      </div>
    `;
  };

  const subMenuItemsHTML = () => {
    if (!sub_menu_items?.length) return '';
    const txt_all = TT('omp_layout::sub_menu_all_mo');
    const active_cate_item = find((item) => {
      return item.active;
    }, sub_menu_items);
    return html`
      <div class="mp-sub-menu-items">
        <ul class="mp-sub-menu-items__ul">
          <li class="mp-sub-menu-items__li" data-active="${active_cate_item ? 'false' : 'true'}">
            <a href="/${TT.lang}/product/list/${sel('0.cate_list_id', sub_menu_items)}">${txt_all}</a>
          </li>
          ${strMap((menu) => {
            return html`
              <li class="mp-sub-menu-items__li" data-active="${menu.active}">
                <a href="${menu.link.href}">${menu.text}</a>
              </li>
            `;
          }, sub_menu_items)}
        </ul>
      </div>
    `;
  };

  return html`
    <div class="omp-header" data-header_banner="${header_banner_type}">
      ${headerBannerHTML()}
      <div class="mp-header-mo-wrapper mobile_v">
        <div class="mp-header-mo">
          ${typeHeaderHtml()}
          <div class="mp-header-mo__cate-lists"></div>
          ${hamMenusHTML({ login_user, ham_menus, wish_count, cart_count, mp_navigations })}
        </div>
      </div>
      <div class="omp-header__all-menus" data-show="false"></div>
      <div class="omp-header__product-list-menus" data-show="false">
        <ul class="omp-header__product-list-menus-ul">
          ${strMap((menu) => {
            const {
              id,
              active = false,
              text,
              link: { href = '', is_new_tab = false },
            } = menu;
            return html`
              <li class="omp-header__product-list-menus-li">
                <a
                  href="${href}"
                  ${is_new_tab ? ' target="_blank"' : ''}
                  ${id ? html`id=${id}` : ''}
                  data-active="${active}"
                >
                  <span class="omp-atom__category-button-text">${text}</span>
                </a>
              </li>
            `;
          }, ham_menus)}
        </ul>
      </div>
    </div>
    ${subMenusHTML()} ${subMenuItemsHTML()}
  `;
};

/**
 * @param {object} param
 * @param {string?} param.search_value 검색 페이지 검색창 초기값
 */
const pcTabletHTML = ({
  type,
  header_banner,
  sub_menu_all,
  sub_menus,
  wish_count,
  cart_count,
  search_value,
  header_banner_type,
} = {}) => {
  const typeHeaderHtml = () => {
    return headerSearchFormHTML(search_value);

    // if (type === OMPCoreLayoutConstantS.HEADER_TYPE.home) {
    //   return homeHeaderMenu({ sub_menus });
    // } else if (type === OMPCoreLayoutConstantS.HEADER_TYPE.search) {
    //   return headerSearchFormHTML(search_value);
    // } else {
    //   return defaultHeaderMenu({ sub_menus });
    // }
  };

  const headerBannerHTML = () => {
    if (!header_banner_type) return '';
    return html`
      <div id="header-banner" class="header-banner" style="background-color: #cfdbf3;">
        <div class="header-banner__wrap">
          <div class="header-banner__img">
            <img
              src="//s3.marpple.co/files/u_4214566/2025/1/original/659d6da1821207f4059c7ae3fc6129c1924f07d52.png"
              alt="2025년 새해 복 많이 받으세요! 1/24(금)이후 출고 건은 1/31(금)부터 순차 발송 예정이며 방문수령은 1/24(금) 오후 5시까지 가능합니다."
            />
          </div>
          <div class="header-banner__btn-close">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M2.89062 2.1084L17.8906 17.1084M2.89062 17.1084L17.8906 2.1084"
                stroke="black"
                stroke-width="1.25"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
    `;
  };

  const active_sub_menu = find((menu) => {
    return menu.active;
  }, sub_menus);

  return html`
    ${headerBannerHTML()}
    <div class="omp-header" data-header_banner="${header_banner_type}">
      <h1 id="logo" class="omp-header__logo">
        <a class="omp-header__logo-a" href="/${TT.lang}/">
          <img
            class="omp-header__logo-img"
            src="//s3.marpple.co/files/u_218933/2023/7/original/f01a70531eac8ab8ced3654146a153db12da80a91.svg"
            alt="MARPPLE"
          />
        </a>
      </h1>
      <div class="omp-header__wrap">
        <div class="omp-header__body">
          <div class="omp-header__body-scroll">${typeHeaderHtml()}</div>
        </div>
        <div class="omp-header__right">
          <div class="omp-header__buttons">
            ${OMPCoreCellTmplS.sideHeaderPc({
              wish_count,
              cart_count,
            })}
          </div>
        </div>
      </div>
      <div class="omp-header__menus" data-active_id="${active_sub_menu?.id}">
        ${defaultHeaderMenu({ sub_menu_all, sub_menus })}
      </div>
      <div class="omp-header__navi">
        <div class="omp-header__navi-bg"></div>
        <div class="omp-header__navi-body"></div>
      </div>
    </div>
  `;
};

/**
 * @typedef {object} HeaderSearchParam
 * @property {string?} value
 */

/**
 * @param {object} [param = {}]
 * @param {object} param.locals
 * @param {HeaderSearchParam} [locals.search = {}]
 * @param {CateList[]} sub_menus
 */
export const headerHTML = ({
  type,
  locals,
  title,
  menu_title,
  sub_menus,
  is_back = true,
  sub_menu_all,
  sub_menu_items,
} = {}) => {
  const { header_banner, policies, login_user, counts, search = {} } = locals;
  const wish_bp_count = counts.wish + counts.wish_products;
  const header_banner_type = header_banner ? 'header_banner' : '';
  const txt_all = TT('omp_layout::sub_menu_all');

  if (locals.ua.is_mobile && !locals.ua.is_tablet) {
    return mobileHTML({
      wish_count: wish_bp_count,
      cart_count: counts.cart,
      search_value: search.value,
      sub_menu_all,
      sub_menus,
      sub_menu_items,
      policies,
      login_user,
      header_banner,
      type,
      is_back,
      title,
      menu_title,
      header_banner_type,
      ham_menus: UtilObjS.deepClone(locals.cate_lists || []),
      mp_navigations: locals.mp_navigations || [],
    });
  } else {
    return pcTabletHTML({
      type,
      header_banner,
      wish_count: wish_bp_count,
      cart_count: counts.cart,
      search_value: search.value,
      sub_menu_all: {
        active: false,
        text: txt_all,
        link: {},
      },
      sub_menus,
      header_banner_type,
    });
  }
};

/**
 * @param {!string} search_value
 */
const headerSearchFormHTML = (search_value) => {
  return OMPCoreAtomTmplS.searchForm({
    placeholder: TT('search::search_01'),
    value: UtilS.escape(search_value),
    input_klass: 'omp-atom__input-auto-complete', // TODO @kjj `AutoCompleteInput` 으로 랩핑하기
    form_klass: 'omp-search__search-form',
    data: { debounce_time: 300 },
  });
};
