export const SERVICE = 'https://api.wowpress.co.kr';
export const FILE_SERVICE = 'https://file.wowpress.co.kr';

export const JWT = {
  token:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ3b3dwcmVzcyBvcmRlciBhcGkiLCJVU0VSTk8iOjE4ODI5NywiaXNzIjoid293cHJlc3MiLCJVU0VSTkFNRSI6Iuq5gOyEoO2ZlCIsIlVTRVJfUk9MRSI6IlJPTEVfVVNFUiIsImV4cCI6MTczNzYzOTQzOCwiaWF0IjoxNzA2MDgyNTEyfQ.ZHVCaRqTqV_IvZqU6P_uoxUWt4IbHpWTM5Um1smTVpI',
  expire_at: '',
};

export const WOW_STATUS = {
  CREATED: 'CREATED',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  ERROR: 'ERROR',
  HOLDING: 'HOLDING',
};

export const LOG_TYPE = {
  CALLBACK: 'CALLBACK',
  ORDER: 'ORDER',
  REORDER: 'REORDER',
  FILE: 'FILE',
  SCHEDULE: 'SCHEDULE',
  CANCEL: 'CANCEL',
};

export const ATTEMPTS = {
  START: 0,
  MAX: 2,
};

export const delivery_info = {
  worker: {
    name: '김지우',
    telm: '010-4565-7671',
    email: 'jw.kim@marpple.com',
  },
  dlvymcd: '1',
  dlvyfr: {
    name: '마플코퍼레이션',
    tel: '010-4565-7671',
    zipcode: '08589',
    sd: '서울',
    sgg: '금천구',
    umd: '가산동',
    addr1: '서울특별시 금천구 가산디지털1로 75-24',
    addr2: '10층 마플코퍼레이션 배송팀 (가산동)',
  },
  dlvyto: {
    name: '김지우(마플코퍼레이션)',
    tel: '010-4565-7671',
    // name: '마플코퍼레이션',
    // tel: '010-2512-7642',
    zipcode: '08589',
    sd: '서울',
    sgg: '금천구',
    umd: '가산동',
    addr1: '서울특별시 금천구 가산디지털1로 75-24',
    addr2: '10층 마플코퍼레이션 배송팀 (가산동)',
    dlvymsg: '1011호 배송팀으로 부탁드립니다',
  },
};
