export const BP_OPTION_GROUP_ID = 199;
/* cm_이라고 쓰여 있지만 사실 mm 임............... */
export const SIZE_70_200 = {
  bp_option_id: 531,
  bp_option_group_id: BP_OPTION_GROUP_ID,
  stand_bottom: {
    x: 297,
    y: 19,
    url: '//s3.marpple.co/files/u_193535/2023/5/original/27b21337b55e295c297922368a533fa0b45d4fa83.png',
    cm_width: 50,
    cm_height: 30,
  },
};

export const SIZE_140_200 = {
  bp_option_id: 532,
  bp_option_group_id: BP_OPTION_GROUP_ID,
  stand_bottom: {
    x: 503,
    y: 19,
    url: '//s3.marpple.co/files/u_193535/2023/5/original/31a7389fe8f44d1140a7905ef8d6095072b7ac3f2.png',
    cm_width: 85,
    cm_height: 40,
  },
};

export const SIZE_200_200 = {
  bp_option_id: 533,
  bp_option_group_id: BP_OPTION_GROUP_ID,
  stand_bottom: {
    x: 710,
    y: 20,
    url: '//s3.marpple.co/files/u_193535/2023/5/original/c689546e886a2445578396eba2dcfc32801303ad1.png',
    cm_width: 120,
    cm_height: 60,
  },
};
export function generalToNess(bp_option_id) {
  if (bp_option_id === SIZE_70_200.bp_option_id) {
    return SIZE_70_200_NESS.bp_option_id;
  }
  if (bp_option_id === SIZE_140_200.bp_option_id) {
    return SIZE_140_200_NESS.bp_option_id;
  }
  if (bp_option_id === SIZE_200_200.bp_option_id) {
    return SIZE_200_200_NESS.bp_option_id;
  }
}

export const SIZE_70_200_NESS = {
  bp_option_id: 1144,
  bp_option_group_id: BP_OPTION_GROUP_ID,
  stand_bottom: {
    x: 297,
    y: 19,
    url: '//s3.marpple.co/files/u_193535/2023/5/original/27b21337b55e295c297922368a533fa0b45d4fa83.png',
    cm_width: 50,
    cm_height: 30,
  },
};
export const SIZE_140_200_NESS = {
  bp_option_id: 1145,
  bp_option_group_id: BP_OPTION_GROUP_ID,
  stand_bottom: {
    x: 503,
    y: 19,
    url: '//s3.marpple.co/files/u_193535/2023/5/original/31a7389fe8f44d1140a7905ef8d6095072b7ac3f2.png',
    cm_width: 50,
    cm_height: 25,
  },
};

export const SIZE_200_200_NESS = {
  bp_option_id: 1146,
  bp_option_group_id: BP_OPTION_GROUP_ID,
  stand_bottom: {
    x: 710,
    y: 20,
    url: '//s3.marpple.co/files/u_193535/2023/5/original/c689546e886a2445578396eba2dcfc32801303ad1.png',
    cm_width: 62,
    cm_height: 25,
  },
};

export const ALL_SIZES = [
  SIZE_70_200,
  SIZE_140_200,
  SIZE_200_200,
  SIZE_70_200_NESS,
  SIZE_140_200_NESS,
  SIZE_200_200_NESS,
];
