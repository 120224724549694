/***
   이벤트가 종료되어 배너를 삭제/변경하고 싶다면 아래 옵션을 수정
 ***/
export const DC_BANNER_EVENT = {
  doing: true, // 이벤트 배너 진행 여부, 해제시 productBanner 를 main 으로 띄웁니다.
  url: '/event/detail/665',
  thumbnail_sub:
    '//s3.marpple.co/files/u_4214566/2024/12/original/a436cc61891ef591526852c4f513b1f21ef05ad11.png',
  thumbnail_mobile:
    '//s3.marpple.co/files/u_4214566/2024/12/original/a8e6cab1b698b11c904f93b367f7a8a8b0a6ac8c1.png',
};

/***
  :type         - 기본 이미지 (이벤트 배너가 doing false 면서 PC 일떄) cloth, biz, etc
  :type_sub     - sub 이미지 (이벤트 배너가 doing true 면서 PC 일떄)
  :type_mobile  - mobile 이미지 (이벤트 doing 상관없이 모바일일때)
 */
export const DC_BANNER_PRODUCT = {
  cloth: '//s3.marpple.co/files/u_1325934/2022/11/original/e3fb8a088eeb3788a3d0f9da3b01b980789a0dc51.jpg',
  cloth_sub: '//s3.marpple.co/files/u_4214566/2024/12/original/93bfce7cb83aa0b437a68c97e6714b6191bca54c4.png',
  cloth_mobile:
    '//s3.marpple.co/files/u_4214566/2024/12/original/b9152e04837308deda1b2cadb6217de4cccb48801.png',

  biz: '//s3.marpple.co/files/u_2550681/2024/1/original/b5cb7dd74c47e156e1b11aced05adda6ee539d4f1.png',
  biz_sub: '//s3.marpple.co/files/u_4214566/2024/12/original/a537677ca38f98be1cd15c6ba2d3c6e7c6b3cf692.png',
  biz_mobile:
    '//s3.marpple.co/files/u_3258367/2023/12/original/0c19dc9e4056c699c5aae5ca45851ec4bb4c15441.png',

  etc: '//s3.marpple.co/files/u_2482215/2024/4/original/e9804cd899b5654773c43d48cf378db213163cd11.jpg',
  etc_sub: '//s3.marpple.co/files/u_4214566/2024/12/original/68114acc45f40c0bfd2d4268bb5c33dbf78ce6813.png',
  etc_mobile: '//s3.marpple.co/files/u_2482215/2024/4/original/fab6339fece281fff9f88d19d5a9dd9ed2d771891.jpg',
};
