import { getSizeFace } from '../../../NewMaker/Checker/S/Function/getSizeFace.js';
import { getBaseProductFacesInMaker, getProductColorInMaker } from '../getSth.js';
import { getCurrentFcanvas, getCvObj } from '../Fcanvas/cv_object.js';
import { each, go, html } from 'fxjs/es';
import { $addClass, $appendTo, $el, $qs, $removeClass } from 'fxdom/es';

export function cvPrintItemEvent() {
  go(
    html`
      <li class="cv_print_item circle_icon">
        <div class="icon"></div>
        <div class="text">템플릿 보기</div>
      </li>
    `,
    $el,
    $appendTo($qs('.decoration_menu_for_pc')),
    $.on('click', async function () {
      const maker_frame_el = $qs('#maker_frame');
      const fcanvas = getCurrentFcanvas();
      if ($.has_class(maker_frame_el, 'cv_print_item')) {
        const cv_print_item = getCvObj(fcanvas._objects, 'cv_print_item');
        go(
          [getCvObj(fcanvas._objects, 'cv_print_area'), getCvObj(fcanvas._objects, 'cv_safety_area')],
          each((v) => (v.visible = true)),
        );
        $removeClass('cv_print_item')($1('#maker_frame'));
        fcanvas.remove(cv_print_item);
      } else {
        $addClass('cv_print_item')(maker_frame_el);
        go(
          [getCvObj(fcanvas._objects, 'cv_print_area'), getCvObj(fcanvas._objects, 'cv_safety_area')],
          each((v) => (v.visible = false)),
        );
        await makeCvPrintItem();
      }
    }),
  );
}

export async function makeCvPrintItem() {
  const fcanvas = getCurrentFcanvas();
  const base_product_faces = getBaseProductFacesInMaker();
  const base_product_size_id = getProductColorInMaker().base_product_size_id;
  const { items } = getSizeFace({
    base_product_faces,
    base_product_size_id,
    base_product_face_id: fcanvas.bpf_id,
  });
  $.don_loader_start();
  return new Promise((resolve) => {
    go(
      items,
      each(async (item) => {
        const url = item.print_item_url;
        const coord = item.px;
        return new Promise((resolve2) => {
          url
            ? fabric.Image.fromURL(url, (cv_obj) => {
                cv_obj.set({
                  opacity: 0.4,
                  // stroke: '#0000ff',
                  // strokeWidth: 2,
                  ...coord,
                });
                G.mp.maker.nscreen(cv_obj);
                cv_obj.selectable = false;
                cv_obj.evented = false;
                cv_obj._data = {
                  cv_type: 'cv_print_item',
                  is_not_design: true,
                };
                fcanvas.add(cv_obj);
                resolve2();
              })
            : coord
              ? go(
                  new fabric.Rect({
                    ...coord,
                    selectable: false,
                    evented: false,
                    // fill: 'transparent',
                    // stroke: '#0000ff',
                    // strokeWidth: 2,
                    opacity: 0.4,
                    fill: 'rgb(255, 0, 0, 1)',
                    _data: {
                      cv_type: 'cv_print_item',
                      is_not_design: true,
                    },
                  }),
                  G.mp.maker.nscreen,
                  (v) => fcanvas.add(v),
                  () => resolve2(),
                )
              : resolve2();
        });
      }),
      () => {
        $.don_loader_end();
        resolve();
      },
    );
  });
}
