import {
  compact,
  eachC,
  eachL,
  filter,
  extend,
  filterL,
  flatten,
  go,
  head,
  isNil,
  mapC,
  mapL,
  merge,
  reduce,
  reverseL,
  sel,
  takeAll,
  zipL,
  zipWithIndexL,
} from 'fxjs/es';
import axios from 'axios';
import { waybill_CJ_label_data_formatter } from './waybill_cj_formating.js';
import { alertWaybillCJstatus } from './alert_windows.js';
import { makeDfProjectionListUpdate } from '../../../Projection/List/F/fs.js';
import { getWaybillCJgoodsNameStr } from '../../S/waybill_fns.js';
import { printWaybillCJ } from './waybill_cj_printing.js';
import { popupChildShippingStatusForShopByChild } from './shipping_status_for_shop.js';
import Swal from 'sweetalert2';
import { guards } from '../../../Lgl/S/Function/guards.js';
import { printLglWaybill } from '../../../Lgl/F/Function/waybill.print.js';
import { alertsLgl } from '../../../Lgl/F/Function/alerts.js';
import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';
import { NotFoundError } from '../../../../Error/B/Function/error.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

export const updatePrintProcessStatus = (process_summary, last_process_idx, is_printed) =>
  go(
    process_summary,
    eachL((prc) => {
      prc.process_idx = prc.status === 'S' ? prc.process_idx + 2 : prc.process_idx + 1;
      if (prc.process_idx === last_process_idx - 1) {
        if (is_printed) {
          prc.process_idx = prc.process_idx + 1;
        } else {
          prc.error.code = '1501';
          prc.error.message = '운송장 출력 실패';
        }
      }
    }),
    takeAll,
  );

const updateWaybillNumberToDB = async (shipping_id, waybill_number) => {
  await axios({
    url: '/@api/waybill/cj/shippings_waybill_number',
    method: 'post',
    data: { shipping_id, waybill_number },
  });
};

const updateWaybillsDataToDB = async (waybill_datas) =>
  await go(
    waybill_datas,
    eachC(
      async (waybill_data) =>
        await updateWaybillNumberToDB(waybill_data.shipping_id, waybill_data.invoice_no_barcode),
    ),
  );

const updateTaskStatusToDB = async ({ projection_id, prj_merged_type, overall_delivery_status }) => {
  switch (overall_delivery_status) {
    case 'all': {
      //모두 운송장 처리 완료되었으면 배송 태스크 => 완료
      await axios({
        url: '/@api/waybill/cj/shipping_task_status',
        method: 'post',
        data: { projection_id, status: 'completed' },
      });

      if (prj_merged_type === 'child') {
        //운송장 발급한 주문서가 병합주문의 자식이라고 하면 형제들이 모두 완료되었는지 확인하고 부모의 배송 태스크도 최신으로 업데이트
        await axios({
          url: '/@api/waybill/cj/update_parent_projection_shipping_task',
          method: 'post',
          data: { projection_id },
        });
      }
      break;
    }
    case 'partial': {
      await axios({
        url: '/@api/waybill/cj/shipping_task_status',
        method: 'post',
        data: { projection_id, status: 'on' },
      });
      break;
    }
  }
};

const uptodateProjectionStatus = async (projection_id) => {
  await axios({
    url: '/@api/waybill/cj/change_projection_status',
    method: 'post',
    data: { projection_id },
  });
};

export const getUserProductsFromProjection = async (projection_id) => {
  return go(
    await axios({
      url: '/@api/waybill/cj/userProductsInfo',
      method: 'get',
      params: { projection_id },
    }),
    sel('data'),
    flatten,
    (goods_info) => getWaybillCJgoodsNameStr(goods_info, projection_id, box().user.name),
  );
};

const mergeAllCombinedUserProducts = async (projection_id, combined_children) => {
  return go(
    [{ id: projection_id }, ...combined_children],
    mapC(async (child) => {
      return go(
        await axios({
          url: '/@api/waybill/cj/userProductsInfo',
          method: 'get',
          params: { projection_id: child.id },
        }),
        sel('data'),
      );
    }),
    flatten,
    (goods_info) => getWaybillCJgoodsNameStr(goods_info, projection_id, box().user.name),
  );
};

const makeShippingTaskCompleteAndProjectionUptodate = async (projection_id) => {
  await axios({
    url: '/@api/waybill/cj/shipping_task_status',
    method: 'post',
    data: { projection_id, status: 'completed' },
  });
  await axios({
    url: '/@api/waybill/cj/change_projection_status',
    method: 'post',
    data: { projection_id },
  });
};

const getCombinedParentChildrenShippingTasks = async (projection_id) =>
  go(
    await axios({
      method: 'get',
      url: '/@api/waybill/cj/combined_parent_children_shipping_task_status',
      params: { combined_projection_id: projection_id },
    }),
    sel('data'),
  );

/**
 * @define 해외 배송 LGL특송 inbound 운송장 출력 로직 (가산 -> LGL 물류센터 CJ 배송)
 * */
export const waybillCjGoToLgl = async (projection) => {
  try {
    const { id: projection_id, collabo_type, merged_type } = projection;
    guards.nullCheck(projection_id, `projection_id is required`);

    // CJ 운송장 출력
    const [{ print_result }] = await printLglWaybill({ projection_id });

    // 운송장 출력이 성공적으로 진행됨
    if (print_result.is_OK) {
      const waybill_number_inbound = print_result.waybill_no;

      const updated_lgl_inbound_shipping_and_task = (
        await axios.post('/@api/waybill/cj/waybill_number_inbound', {
          projection_id,
          waybill_number_inbound,
        })
      ).data;

      if (UtilS.isEmpty(updated_lgl_inbound_shipping_and_task)) {
        throw NotFoundError('국내 운송장 업데이트를 시도한 배송 정보가 없습니다. (개발자 문의)');
      }

      makeDfProjectionListUpdate();

      if (collabo_type === 'creator' && merged_type === 'child') {
        // 마플샵 병합 자식주문 배송 처리할 때는 부모 태스크 처리까지 책임짐
        await axios({
          url: '/@api/waybill/cj/update_parent_projection_shipping_task',
          method: 'post',
          data: { projection_id },
        });
      }
    }
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
};

export const waybillCJgo = async ({ projection_id, force = false, is_override = false, is_ness }) => {
  try {
    $.don_loader_start();
    const returning_data = go(
      await axios({
        method: 'post',
        url: '/@api/waybill/cj/req_booking',
        data: {
          projection_id,
          force,
          is_ness,
        },
      }),
      sel('data'),
    );

    const data = returning_data.data;
    const api_data = returning_data.data.CJ_apis;
    const prc = returning_data.process;

    const process_summary = go(
      zipWithIndexL(prc.shippings_check),
      mapL(([idx, prc_shipping]) => {
        return [
          prc_shipping,
          prc?.CJ_apis_check?.reservation_params_list_check?.[idx],
          prc?.CJ_apis_check?.address_reference_params_list_check?.[idx],
          prc?.CJ_apis_check?.CJ_bookings_check?.[idx],
        ];
      }),
      mapL((prcs) => {
        return go(zipWithIndexL(compact(prcs)), (prcs) =>
          reduce(
            (acc, [idx, prc]) => {
              if (isNil(acc)) acc = extend(prc, { process_idx: idx });
              if (!isNil(prc) && Object.keys(prc).length) {
                acc = extend(prc, { process_idx: idx });
              }
              return acc;
            },
            null,
            prcs,
          ),
        );
      }),
      zipL(data.shippings),
      mapL((x) => merge(...x)),
      zipL(data.CJ_apis?.reservation_params_list),
      mapL((x) => merge(...x)),
      takeAll,
    );

    if (process_summary.length === 1) {
      // 단건인데 shipping 처리 에러인 경우에는 바로 error 냄
      const error = process_summary[0].error;
      if (error.code !== '0000') {
        await alertWaybillCJstatus.process_fail(error, false, projection_id);
        return;
      }
    }

    const waybill_print_data = go(
      api_data?.CJ_bookings,
      zipWithIndexL,
      //프린트 해야할 배송정보들은 최종 택배 예약 처리까지 완료된 친구들만 진행
      filterL(([_, booking_result]) => booking_result?.RESULT_CD === 'S'),
      (passed_idx_arr) =>
        reduce(
          (acc, [idx, _]) => {
            acc.push({
              projection_id,
              shipping_id: returning_data.data.shippings[idx].id,
              is_inhouse: returning_data.data.shippings[idx].is_inhouse,
              inhouse_label_text: returning_data.data.shippings[idx].inhouse_label_text,
              ...api_data.address_reference_params_list[idx],
              ...api_data.reservation_params_list[idx],
            });
            return acc;
          },
          [],
          passed_idx_arr,
        ),
      reverseL,
      takeAll,
      waybill_CJ_label_data_formatter,
    );

    //Combined 된 주문서들이 존재 => user products 명을 merge
    if (data.projections.combined_children.length) {
      //combined의 parent 주문서는 개별일 수 없다고 가정 (head 처리)
      head(waybill_print_data).goods_name = await mergeAllCombinedUserProducts(
        projection_id,
        data.projections.combined_children,
      );
    } else {
      //parent 자신만 있는 경우 상품명을 projection 에서 가져옴.
      //개별일 경우 shippings table 에 상품명 column 을 만들어서 그 값으로 모든 shipping 정보들에 업데이트
      //현재는 모든 shipping row 들에 projection 의 user products 이름들을 동일하게 적용
      await go(
        waybill_print_data,
        eachL(async (data) => (data.goods_name = await getUserProductsFromProjection(projection_id))),
        takeAll,
      );
    }

    /*
     * process_index
     * 1: 주문 정보 확인 완료 후
     * 2: 배송 정보 확인 완료 후
     * 3: 예약 정보 확인 완료 후 (운송장 발급)
     * 4: 주소 정제 확인 완료 후
     * 5: 택배 예약 완료 후
     * 6: 운송장 출력 완료 후
     * */
    const COMPLETE_PROCESS_INDEX = 6;

    /* 0. 운송장 출력 and 프로세스 업데이트 */
    const {
      is_OK: is_printed,
      reason,
      error,
    } = is_override
      ? { is_OK: true }
      : await printWaybillCJ(waybill_print_data, {
          is_print_prj_qr: true,
          additional_label_datas: null,
          allowed_devices: ['ZD420', 'ZD421'],
        });
    updatePrintProcessStatus(process_summary, COMPLETE_PROCESS_INDEX, is_printed);

    if (!is_printed && reason != null) {
      return await Swal.fire({
        icon: 'error',
        width: 500,
        backdrop: true,
        title: '에러 발생',
        imageUrl: error?.image ?? '',
        imageWidth: 200,
        html: `<span>${reason}</span>`,
        showConfirmButton: true,
      });
    }

    /* 2. 음성 재생 */
    // 음성 legacy
    // if (Number(returning_data.counts.total_shippings) > 1) {
    //   // 한 projection에 multiple shippings 인 경우 대응 (개별 배송)
    //   await overallDeliveryStatusAudioPlay(returning_data.overall_deliver_status);
    // } else {
    //   // projection 하나에 single shipping 인 경우 (일반적인 경우)
    //   const code = '' + head(process_summary).error.code;
    //   await downloadSingleAudioThenPlay({
    //     audio_data_title: 'waybill_CJ_audios',
    //     single_speech_data: waybill_CJ_speech_data[code],
    //     audio_key: code,
    //     is_play: true,
    //   });
    // }

    /* 3. 발급된 운송장 번호 DF에 업데이트 */
    is_printed && (await updateWaybillsDataToDB(waybill_print_data));

    /* 4. 배송 task 처리
     *   3.1 모두 처리된 경우 배송 태스크 완료
     *   3.2 일부 처리된 경우 배송 태스크 진행 중
     *   3.3 병합주문의 자식인 경우 형제들의 배송 태스크가 모두 처리된지 확인하고 부모 병합 주문선의 배송 태스크도 완료 처리 (완료된 경우 부모의 주문서 상태도 업데이트) */
    (is_printed || returning_data?.is_all_waybill_number_existed) &&
      (await updateTaskStatusToDB({
        projection_id,
        prj_merged_type: data.projections.merged_type,
        overall_delivery_status: returning_data.overall_deliver_status,
      }));

    /* 5. 주문서 상태 최신 업데이트 (delivering 시 sms 알림) */
    is_printed && (await uptodateProjectionStatus(projection_id));

    /* 6. Refresh DOM */
    is_printed && makeDfProjectionListUpdate();
    $.don_loader_end();

    /* 7. 프로세스 팝업 */
    await alertWaybillCJstatus.process({
      process_summary,
      title: `택배 운송장 처리 현황 [주문번호 - ${projection_id}]`,
      icon: !is_printed
        ? 'error'
        : returning_data.overall_deliver_status === 'all'
          ? 'success'
          : returning_data.overall_deliver_status === 'partial'
            ? 'warning'
            : 'error',
      // is_timer: false,
      is_timer: is_printed && returning_data.overall_deliver_status === 'all',
      complete_process_idx: COMPLETE_PROCESS_INDEX,
      stop_event_propagation: data.projections.merged_type === 'child',
    });

    /* 묶음 배송들의 부모주문이 운송장 발급 성공한 경우
     *   1. 자신의 배송 태스크 상태값 성공 처리
     *   2. 자신의 태스크와 waybill number 는 일괄 업데이트 됨.
     *   3. 추가로 모든 combined_children 의 배송 정보에 부모의 waybill number 를 모두 자식들에게 업데이트
     * */
    if (data.projections.combined_children.length) {
      await go(
        returning_data.data.projections.combined_children,
        mapC(async (child_projection) => {
          await go(
            child_projection.shippings,
            mapC(async (shipping) =>
              updateWaybillNumberToDB(shipping.id, head(waybill_print_data).invoice_no_barcode),
            ),
          );
        }),
      );
      extend(returning_data.data.projections.shipping_task, {
        status: 'completed',
        type: 'delivering',
      });
      await alertWaybillCJstatus.combined_shipping_tasks(
        returning_data.data.projections,
        returning_data.data.projections.type === 'parent',
      );
    }

    /* 병합 -> 제작용 자식 주문들인 경우 형제들의 상황 보고 (모든 배송 태스크 완료시에만 알람) */
    if (data.projections.merged_type === 'child') {
      await popupChildShippingStatusForShopByChild({
        projection_id,
        is_pop_up_only_all_merged_complete: true,
      });
    }
  } catch (e) {
    $.don_loader_end();
    if (e.response) {
      const returning_data = e.response.data;
      console.error(e.response.status, returning_data);
      const error_code = returning_data.last_error_result?.code ?? '9999';
      if (error_code === '1107') {
        // Combined 주문인 경우
        // 1. 본인 주문 배송 태스크 바로 완료 처리, 주문서 상태 업데이트
        await makeShippingTaskCompleteAndProjectionUptodate(projection_id);
        makeDfProjectionListUpdate();

        // 2. 묶음 배송 처리 상태 alert
        const combined_projection_data = await getCombinedParentChildrenShippingTasks(projection_id);
        await alertWaybillCJstatus.combined_shipping_tasks(
          combined_projection_data,
          returning_data.data.projections.type === 'parent',
        );
      } else if (error_code === '1108') {
        // 묶음 배송[parent] 중 배송 미완료 combined 주문건 존재 alert
        await alertWaybillCJstatus.combined_shipping_tasks(
          returning_data.data.projections,
          returning_data.data.projections.type === 'parent',
        );
      } else {
        const {
          data: {
            projections: { merged_type, status },
          },
        } = returning_data;

        const overrideEnable =
          merged_type === 'child' &&
          (status === 'printing' || status === 'before_print') &&
          !(await isOnlyOneLeftShipItem(projection_id)) &&
          (error_code === '1102' || error_code === '1103');
        // 1102: before_print (제작 준비중 태스크 진행중, 제작 바로 전)
        // 1103: printing (제작 태스크 진행중, 배송 바로 전)

        await alertWaybillCJstatus.process_fail(
          returning_data?.last_error_result,
          overrideEnable,
          projection_id,
        );
      }
    }
    console.error(e);
  } finally {
    $.don_loader_end();
  }
};

const isOnlyOneLeftShipItem = async (projection_id) => {
  const siblings_shipping_task = go(
    await axios({
      url: '/@api/waybill/cj/siblings_shipping_statuses',
      method: 'get',
      params: { child_projection_id: projection_id },
    }),
    sel('data'),
    filter((ship_info) => ship_info.status === 'ready'),
  );
  return siblings_shipping_task.length === 1;
};
