import {
  each,
  filter,
  find,
  go,
  identity,
  mapC,
  pick,
  pipe,
  pluck,
  reject,
  sel,
  selEq,
  some,
  take,
  tap,
  uniq,
} from 'fxjs/es';
import axios from 'axios';
import {
  getThumbnailAssocCompositeTemplates,
  makeFullNewProductWithBothThumbnails,
  makeFullNewProductWithNormalThumbnails2,
  updateProductThumbnailsAndOgImage,
} from '../../../Composite/Thumbnail/F/fs.js';
import { setProductOnlyDesignFaceUrl, uploadThumbProductFace } from '../draw_product_faces.js';
import { makeMockupSize } from '../../../Composite/Core/F/mockup_fns.js';
import { WE_BP_IDS } from '../../../NewMaker/We/S/Function/checker.js';

/*하나의 base_product로 만들어진 모든 product_colors들 모든 composite_template을 새로 적용할때*/
G.jip___abc = 5;
// const reject_product_ids = [
//   8720970, 8320376, 8258137, 6844761, 6799792, 6799726, 6799670, 6774242, 6608212, 6463024, 6462539, 6462538,
//   6462536, 6462532, 6462528, 6462523, 6462515, 6462513, 6462511, 6462508, 6462504, 6462502, 6286620, 6280926,
//   6280831, 3353078, 3310693, 3310646,
// ];
// const bp_ids_for_reject_product_ids = [3125, 2925, 3047, 3013];
function updateFilter(pc, composite_templates) {
  return go(
    pc.thumbnails.value,
    some((thumb) => {
      const composite_template = find(selEq('id', thumb.composite_template_id))(composite_templates);
      return thumb?.updated_at !== composite_template?.updated_at;
    }),
  );
}
function updateFilter2(pc, composite_templates) {
  return go(
    pc.thumbnails.value,
    some((thumb) => {
      const composite_template = find(selEq('id', thumb.composite_template_id))(composite_templates);
      if (!composite_template?.updated_at) return;
      if (!thumb?.updated_at) return;
      return thumb.updated_at !== composite_template.updated_at;
    }),
  );
}

const jip__updatePcThumbnailOgImagePrice = async function () {
  const product_ids = [
    16177605, 16177607, 16177608, 16177609, 16177611, 16177613, 16177614, 16177616, 16177617, 16177621,
    16177622, 16177625, 16177627, 16177631, 16177634, 16177636, 16177638, 16177641, 16177591, 16177592,
    16177596, 16177600, 16177602, 16177604,
  ];
  await go(
    product_ids,
    each(async (product_id) => {
      const pc = await $.get('/@api/product_color', { id: product_id });
      console.log(pc);
      const { thumbnails, og_image_url } = await makeFullNewProductWithNormalThumbnails2(pc);
      await axios.post('/@api/product_color/update_jip_creator', {
        thumbnails,
        og_image_url,
        product_id,
      });
    }),
  );
};

export const updatePcForCompositeThumbnailByBpId = async function (
  obj,
  is_with_og,
  is_composite,
  is_only_og,
  change_composite,
  select_new_thumb,
  composite_template_id,
) {
  if (composite_template_id) return $.alert('nonono');
  const { data: composite_templates } = await axios.get(
    `/@api/composite/composite_template_only_updated_at`,
    {
      params: { base_product_id: obj.cond.base_product_id },
    },
  );
  const products = await go(
    axios.post('/@api/composite/test_page/get_pcs', obj),
    sel('data'),
    tap((products) => console.log('총 : ', products.length)),
    is_composite && !obj.cond?.base_product_color_id
      ? filter((pc) => pc.thumbnails.type !== 'composite_template' || updateFilter(pc, composite_templates))
      : tap(() => {}),
    tap((products) => console.log('적용할 product 수 : ', products.length)),
  );
  const { data: base_product_colors } = await axios.get('/@api/prerequisite_maker/base_product_colors', {
    params: { base_product_id: obj.cond.base_product_id },
  });
  console.log(base_product_colors);
  console.log(`base_product_id : ${obj.cond.base_product_id} 시작!!!`);
  console.log(`base_product_color_id : ${obj.cond.base_product_color_id}`);
  console.log('is_with_og : ', is_with_og);
  console.log('is_composite : ', is_composite);
  console.log('is_only_og : ', is_only_og);
  console.log('select_new_thumb : ', select_new_thumb);
  console.log(
    'change_composite : ',
    change_composite.dest_ct_id,
    '을',
    change_composite.src_ct_id,
    '로 바꾸다.',
  );
  console.log('이미 합성된 애들은 안건드리기 : ', obj.with_out_created_composite_thumbnail);
  let i = 0;
  await go($.confirm('시작하겠습니까?'), function (bool) {
    if (!bool) {
      $.alert('취소됐습니다.');
      return;
    }
    console.time('aaaaaaaa');
    return go(
      products,
      (procuts) => _p.how_many(procuts, G.jip___abc),
      each(
        mapC(
          pipe(async (product) => {
            const abc = `${++i}: ${product.id} product_id`;
            console.log(abc);
            if (!find((bpc) => bpc.id === product.base_product_color_id)(base_product_colors)) {
              const new_product = await makeFullNewProductWithBothThumbnails(
                product,
                is_with_og,
                false,
                null,
                is_only_og,
                change_composite,
                select_new_thumb,
              );
              console.log('-------no_color---------------');
              console.log(`--------marpple.shop/kr/${product.domain_name}/products/${product.id}`);
              await axios.post(
                '/@api/product_color/update_jip',
                pick(
                  is_only_og
                    ? ['id', 'og_image_url']
                    : is_with_og
                    ? ['id', 'og_image_url', 'thumbnails']
                    : ['id', 'thumbnails'],
                )(new_product),
              );
              console.log(`--------marpple.shop/kr/${product.domain_name}/products/${product.id}`);
              console.log(`--------marpple.lvh.me:9077/kr/${product.domain_name}/products/${product.id}`);
              return;
            }
            try {
              const new_product = await makeFullNewProductWithBothThumbnails(
                product,
                is_with_og,
                is_composite,
                null,
                is_only_og,
                change_composite,
                select_new_thumb,
              );
              await axios.post(
                '/@api/product_color/update_jip',
                pick(
                  is_only_og
                    ? ['id', 'og_image_url']
                    : is_with_og
                    ? ['id', 'og_image_url', 'thumbnails']
                    : ['id', 'thumbnails'],
                )(new_product),
              );
              console.log(`--------marpple.shop/kr/${product.domain_name}/products/${product.id}`);
              console.log(`--------marpple.lvh.me:9077/kr/${product.domain_name}/products/${product.id}`);
            } catch (e) {
              console.log('error-------------------------------------------------');
              console.log(`--------marpple.shop/kr/${product.domain_name}/products/${product.id}`);
              console.log(`--------marpple.lvh.me:9077/kr/${product.domain_name}/products/${product.id}`);
              throw e;
            }
          }),
        ),
      ),
    );
  });
  console.timeEnd('aaaaaaaa');
  console.log(`base_product_id: ${obj.cond.base_product_id} 끝!!`);
};
// 338, 252, 339
const changed_composite_templates = [
  { target_id: 252, changed_id: 251, is_remove: true },
  { target_id: 338, changed_id: 2339 },
  { target_id: 339, changed_id: 2341 },
];
window.__changed_composite_templates = changed_composite_templates;
export const updatePcForCompositeThumbnail = async function (
  base_product_id,
  map_c_length,
  { base_product_color_id, omit_base_product_color_ids, composite_template_ids, store_id } = {},
) {
  if (base_product_id == null || map_c_length == null)
    throw new Error(`base_product_id, offset, limit, map_c_length 는 필수 사항 입니다.`);
  let is_first = map_c_length === 1;
  const { data: composite_templates } = await axios.get(
    `/@api/composite/composite_template_only_updated_at`,
    {
      params: { base_product_id },
    },
  );
  const data = await go(
    axios.get('/@api/composite/test_page/get_product_colors_for_ct_migration', {
      params: {
        base_product_id,
        base_product_color_id,
        store_id,
      },
    }),
    sel('data'),
  );
  const assoc_composite_templates = await $.get('/@api/composite/assoc_composite_templates', {
    base_product_id,
    composite_template_type: 'thumbnail',
  });
  let composite_templates_not_to_be_changed = [];
  const products = go(
    data.products,
    filter((pc) => pc.thumbnails?.type === 'composite_template'),
    tap((products) => console.log('총 : ', products.length)),
    base_product_color_id
      ? identity
      : omit_base_product_color_ids
      ? reject((pc) => omit_base_product_color_ids.includes(pc.base_product_color_id))
      : composite_template_ids
      ? filter((pc) =>
          updateFilter2(
            pc,
            go(
              composite_templates,
              filter((ct) => ct.is_public),
              filter((ct) => composite_template_ids.includes(ct.id)),
            ),
          ),
        )
      : filter((pc) => updateFilter2(pc, composite_templates)),
    tap((products) => console.log('적용할 product 수 : ', products.length)),
    each((pc) => {
      pc.thumbnails.value = go(
        pc.thumbnails.value,
        reject((t) => {
          const changed_composite_template = go(
            changed_composite_templates,
            find(({ target_id }) => target_id === t.composite_template_id),
          );
          return pc.thumbnails.value?.length > 1 && changed_composite_template?.is_remove;
        }),
      );
      pc.thumbnails.value = go(
        pc.thumbnails.value,
        each((t) => {
          const changed_composite_template = go(
            changed_composite_templates,
            find(({ target_id }) => target_id === t.composite_template_id),
          );
          if (changed_composite_template?.changed_id) {
            t.composite_template_id = changed_composite_template.changed_id;
          }
        }),
      );
    }),
    filter((pc) => {
      const ct_ids = go(pc.thumbnails.value, pluck('composite_template_id'));
      const _assoc_composite_templates = go(
        assoc_composite_templates,
        ct_ids?.length ? filter((ct) => ct_ids.includes(ct.id)) : identity,
        take(10),
      );
      if (_assoc_composite_templates.length !== pc.thumbnails.value.length) {
        console.log(pc.id);
        composite_templates_not_to_be_changed = go(
          composite_templates_not_to_be_changed.concat(
            ct_ids.filter((ct_id) =>
              go(_assoc_composite_templates, pluck('id'), (ids) => !ids.includes(ct_id)),
            ),
          ),
          uniq,
        );
        return;
      }
      return true;
    }),
    tap((products) =>
      console.log(
        '현재 적용할수 없는 썸네일을 제외(현재 추적 할수 없는 합성 템플릿이 포함됌) 하고 적용할 product 수 : ',
        products.length,
      ),
    ),
  );
  console.log(`composite_templates_not_to_be_changed: ${composite_templates_not_to_be_changed.join(', ')}`);
  if (!products.length) return $.alert('적용할 products 가 없습니다.');
  const { data: base_product_colors } = await axios.get('/@api/prerequisite_maker/base_product_colors', {
    params: { base_product_id },
  });
  console.log(`base_product_id : ${base_product_id} 시작!!!`);
  console.log(`base_product_color_id : ${base_product_color_id}`);
  let i = products.length;
  await go($.confirm('시작하겠습니까?'), function (bool) {
    if (!bool) {
      $.alert('취소됐습니다.');
      return;
    }
    console.time('aaaaaaaa');
    return go(
      products,
      (product) => _p.how_many(product, map_c_length),
      each(
        mapC(
          pipe(async (product) => {
            const ct_ids = go(product.thumbnails.value, pluck('composite_template_id'));
            if (!ct_ids?.length) return;
            const pc = await $.get('/@api/composite/test_page/get_only_product_color_for_ct_migration', {
              product_color_id: product.id,
            });
            const _assoc_composite_templates = go(
              assoc_composite_templates,
              ct_ids?.length ? filter((ct) => ct_ids.includes(ct.id)) : identity,
              take(10),
              (assoc_composite_templates) => JSON.parse(JSON.stringify(assoc_composite_templates)),
            );
            const abc = `${--i}: ${product.id} product_id`;
            if (find((bpc) => bpc.id === product.base_product_color_id)(base_product_colors)) {
              try {
                await updateProductThumbnailsAndOgImage({
                  pc,
                  assoc_composite_templates: _assoc_composite_templates,
                  with_og: true,
                });
                const id = pc.id;
                // const og_image_url = pc.og_image_url;
                const thumbnails = pc.thumbnails;
                if (id !== product.id) throw new Error('product_id 가 일치 하지 않습니다.');
                // if (!og_image_url) throw new Error('no og_image_url ');
                if (!thumbnails) throw new Error('no thumbnails ');
                if (is_first) {
                  console.log(`--------/${product.domain_name}/products/${product.id}`);
                  const is_ready = await $.confirm('Are you ready???');
                  if (!is_ready) throw new Error('Not Ready');
                }
                await axios.post('/@api/product_color/update_jip', { id, thumbnails });
                console.log(`--------/${product.domain_name}/products/${product.id}`);
                if (is_first) {
                  await $.confirm('Is it good??');
                  is_first = false;
                  throw new Error('test');
                }
              } catch (e) {
                console.log('error-------------------------------------------------');
                console.log(`--------/${product.domain_name}/products/${product.id}`);
                if (e.message === 'test') throw e;
              }
            }
          }),
        ),
      ),
    );
  });
  console.timeEnd('aaaaaaaa');
  console.log(`base_product_id: ${base_product_id} 끝!!`);
};
window.jip___updatePcForCompositeThumbnail = updatePcForCompositeThumbnail;
export const updatePcForOnlyDesignByBpId = async function (base_product_id) {
  const { data: products } = await axios.get('/@api/composite/get_pcs_for_composite_by_bp_id', {
    params: { base_product_id },
  });
  const assoc_composite_templates = await getThumbnailAssocCompositeTemplates({ base_product_id }, true);
  if (!assoc_composite_templates.length) throw new Error('assoc_composite_templates 없음');
  const size = await makeMockupSize(assoc_composite_templates);
  console.log(size);
  let i = 0;
  console.log(`base_product_id: ${base_product_id}`);
  console.log(`총 프로덕트 개수: ${products.length}`);
  go(
    products,
    reject(({ product_faces2 }) => product_faces2.value[0].only_design_face_url),
    tap((products) => console.log(`실제 업데이트할 총 프로덕트 개수: ${products.length}`)),
  );
  await go($.confirm('console창 확인해 주세요. 맞습니까?'), function (bool) {
    if (!bool) {
      console.log('취소됨');
      $.alert('취소됐습니다.');
      return;
    }
    G.mp.maker.is_auto_print = true;
    return go(
      products,
      reject(({ product_faces2 }) => product_faces2.value[0].only_design_face_url),
      (products) => _p.how_many(products, 6),
      each(
        mapC(async (product) => {
          try {
            const abc = `${++i}: ${product.id} product_id`;
            console.log(abc);
            await setProductOnlyDesignFaceUrl(product, size);
            console.log(product);
            await axios.post('/@api/product_color/update_jip', pick(['id', 'product_faces2'])(product));
            console.log(`/${product.domain_name}/products/${product.id} --is_public-${product.is_public}`);
            return product;
          } catch (e) {
            console.log(`product_color_id: ${product.id} ----- fail!!!!!!!!!!!!!!!!`);
            return product;
          }
        }),
      ),
      () => {
        G.mp.maker.is_auto_print = false;
        console.log(`base_product_id: ${base_product_id} 끝!!`);
      },
    );
  });
};

async function migrateDesignSize() {
  const we_bp_ids = [5940, 5945].concat(WE_BP_IDS);
  const masking_tape_bp_ids = [5200, 5203, 5205, 5206, 5207, 5208];
  await go(
    we_bp_ids.concat(masking_tape_bp_ids),
    each(async (base_product_id) => {
      console.log(base_product_id, 'start');
      const { data: original_products } = await axios.get('/@api/product_color/all_products_for_migrate', {
        params: { base_product_id },
      });
      console.log(original_products.length);
      const i = original_products.length;
      // await go(
      //   original_products,
      //   each(async (product) => {
      //     try {
      //       console.log(`${--i}: ${product.id} product_id`);
      //       await setPfDesignSize({
      //         product_faces2: product.product_faces2,
      //         base_product_size_id: product.base_product_size_id,
      //       });
      //       await axios.post('/@api/product_color/update_jip', pick(['id', 'product_faces2'])(product));
      //       return product;
      //     } catch (e) {
      //       console.log(`product_color_id: ${product.id} ----- fail!!!!!!!!!!!!!!!!`);
      //       return product;
      //     }
      //   }),
      //   () => {
      //     console.log(base_product_id, 'done');
      //   },
      // );
    }),
  );
}
G.___migrateDesignSize = migrateDesignSize;

async function migrateDesignSizeChilderen() {
  const we_bp_ids = [5940, 5945].concat(WE_BP_IDS);
  const masking_tape_bp_ids = [5200, 5203, 5205, 5206, 5207, 5208];
  await go(
    we_bp_ids.concat(masking_tape_bp_ids),
    each(async (base_product_id) => {
      console.log(base_product_id, 'start');
      const { data: original_products } = await axios.get('/@api/product_color/all_products_for_migrate', {
        params: base_product_id,
      });
      console.log(original_products.length);
      let i = original_products.length;
      await go(
        original_products,
        each(async (op) => {
          try {
            console.log(`${--i}: ${op.id} product_id`);
            await axios.post('/@api/product_color/update_design_size_children', {
              original_product_id: op.id,
            });
            return op;
          } catch (e) {
            console.log(`product_color_id: ${op.id} ----- fail!!!!!!!!!!!!!!!!`);
            return op;
          }
        }),
        () => {
          console.log(base_product_id, 'done');
        },
      );
    }),
  );
}

async function migrateProductColorThumbnail(up_ids) {
  await go(
    up_ids,
    each(async (up_id) => {
      const { data: product } = await axios.get('/@api/product_color/up', {
        params: { up_id },
      });
      await go(
        product.product_faces2.value,
        each(async (pf) => {
          const temp_thumb_url = await uploadThumbProductFace(pf, 800);
          console.log(temp_thumb_url);
          pf.temp_thumb_url = null;
          pf.preview_thumbnail_url = temp_thumb_url;
        }),
      );
      await axios.post('/@api/product_color/update_jip', {
        id: product.id,
        product_faces2: product.product_faces2,
      });

      const { data: printable_product } = await axios.get('/@api/product_color/up_printable', {
        params: { up_id },
      });
      if (!printable_product) return;
      await go(
        printable_product.product_faces2.value,
        each(async (pf) => {
          const temp_thumb_url = await uploadThumbProductFace(pf, 800);
          console.log(temp_thumb_url);
          pf.temp_thumb_url = null;
          pf.preview_thumbnail_url = temp_thumb_url;
        }),
      );
      await axios.post('/@api/product_color/update_jip', {
        id: printable_product.id,
        product_faces2: printable_product.product_faces2,
      });
    }),
  );
}
G.migrateProductColorThumbnail = migrateProductColorThumbnail;
