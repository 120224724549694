import { defaults, go, html } from 'fxjs/es';
import { NewMakerConditionAgreeTextF } from '../../../NewMaker/ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';
import { makeCvTextImageMarpplizerHtml } from '../CvTextImage/tmpl.js';
import { $delegate } from 'fxdom/es';
import {
  findFontFamily,
  getFontColors,
  makeAngleFunctionEvent,
  makeCharSpacingFunctionEvent,
  makeCvTextImageColorItemEvent,
  makeItProperCvTextImage,
  makeLineHeightFunctionEvent,
  makeSelectFontAndStyleEvent,
  openSelectFonts,
} from '../CvTextImage/fs.js';
import { getFonts } from '../util.js';
import { DEFAULT_PATTERN, makePatternEvent } from '../CvPattern/fs.js';
import { OMPDosuF } from '../../../OMP/Dosu/F/Function/module/OMPDosuF.js';
import { isCvPureText } from '../text.js';

const event = (el) =>
  go(
    el,
    $delegate(
      'click',
      '.maker-color-picker .maker-color-picker__item:not(.selected)',
      makeCvTextImageColorItemEvent,
    ),
    $delegate(
      'click',
      '.select_font_and_style.section .style button:not(.selected)',
      makeSelectFontAndStyleEvent,
    ),
    $.on('click', '.dosu-colors .color-picker', OMPDosuF.handleDosuColorPicker.text),
    $delegate('click', '.select_font', openSelectFonts),
    makeCharSpacingFunctionEvent,
    makeLineHeightFunctionEvent,
    makeAngleFunctionEvent,
    makePatternEvent,
    (el) => NewMakerConditionAgreeTextF.clickEvent(el, '.condition_agree_text'),
  );

export const marpplizer_cv_text_image = async function () {
  await getFonts();
  const cv_active = G.mp.maker.active();
  if (
    !['cv_text_image', 'cv_text', 'cv_pure_text', 'cv_text_image_pattern'].includes(cv_active?._data?.cv_type)
  )
    throw Error('no_active');
  async function f(cv_active) {
    const font = findFontFamily(cv_active);
    const cv_text_image = await makeItProperCvTextImage(cv_active);
    const cv_text = isCvPureText(cv_text_image) ? cv_text_image : cv_text_image._data.text_info;
    return { font, cv_text, cv_text_image };
  }
  const { font, cv_text, cv_text_image, pattern_data } = await go(undefined, async function () {
    if (cv_active._data.cv_type === 'cv_text_image_pattern') {
      const { font, cv_text, cv_text_image } = await f(cv_active._data.cv_text_image_attrs);
      const pattern_data = defaults(cv_active._data.pattern_data, DEFAULT_PATTERN);
      return { font, cv_text, cv_text_image, pattern_data };
    } else {
      const { font, cv_text, cv_text_image } = await f(cv_active);
      const pattern_data = DEFAULT_PATTERN;
      return { font, cv_text, cv_text_image, pattern_data };
    }
  });
  const font_colors = await getFontColors();
  if (cv_active !== G.mp.maker.active()) throw new Error('no_active');
  if (!cv_text) throw new Error('wrong_data');
  return {
    name: 'text',
    app: 'base2',
    html: html`
      <div class="activity">
        ${makeCvTextImageMarpplizerHtml(cv_text, font, font_colors, cv_text_image, pattern_data, cv_active)}
      </div>
    `,
    event,
  };
};
